.cta-buttons {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
}

.cta-buttons .menu-toggle {
  margin-right: 20px;
}

.menu-close,
.cta-buttons .menu-toggle,
.cta-buttons .cta-button {
  border-radius: 20px;
  padding: 10px;
  font-weight: $bold;
    border: 2px solid $charcoal;
    background-color: $alabaster;
    color: $charcoal;
    transition: .3s;

  &:hover {
    box-shadow: 8px 8px $mint;
    transition: .3s;
    background-color: $charcoal;
    color: $alabaster;
  }
}

.menu-overlay {
  position: fixed;
  top: -100%; // Start off-screen
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: $charcoal;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: top 0.5s ease; // Smooth transition for sliding in/out
}

.menu-overlay--visible {
  top: 0; // Slide in to be fully visible
}

.menu-overlay--hidden {
  top: -100%; // Slide out to hide
}

.menu-overlay a {
  color: $alabaster;
  font-size: 4vw;
  cursor: pointer;
  position: relative;

  &:before {
      content: '';
      background-color: $mint;
      position: absolute;
      left: 0;
      bottom: 3px;
      width: 100%;
      height: 8px;
      z-index: -1;
      transition: all .3s ease-in-out;
  }

  &:hover {
    color: $charcoal;
    &:before {
      bottom: 0;
      height: 100%;
    }
  }
}

.menu-close {
  position: absolute;
  right: 8.5vw;
  top: 2vh;
}

.menu-overlay .cloud-svg {
  position: absolute;
  top: 10px;
  left: 0;
}

