#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $charcoal;
  min-height: 100vh;
  height: fit-content;

  .custom-shape-divider-bottom-1708508797 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1708508797 svg {
    position: relative;
    display: block;
    width: calc(100% + 100px); /* Increase width */
    left: -50px; /* Adjust based on the extra width to center it */
    height: 90px;
  }
}