
// Use the font in other SCSS files
html {
  background-color: $alabaster;
}

body {
  font-family: 'Quicksand', sans-serif;
  font-optical-sizing: auto;
  font-weight: $normal;
  font-style: normal;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'BladeRushHeading', serif;
  font-weight: $normal;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 44px;
}

strong {
  font-weight: $bold;
}

section {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 60px;
}

.daisy-svg {
  fill: $pink-lavender;
}