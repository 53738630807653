#hero {
  .heading,
  .sub-heading {
    opacity: 0;
  }

  .heading {
    position: relative;
    animation: fadeInUp 1s ease forwards;
    animation-delay: 1s;
  }
  h2 {
    font-family: 'Quicksand', sans-serif;
    line-height: 1;
    position: absolute;
    top: 13.5vw;
    right: 18.9vw;
    margin: 0;
    opacity: 0;
    width: 6vw;
    font-size: 26px;
    text-align: center;
    z-index: 3;
  }
  h3 {
    font-size: 9.5vw;
    text-align: center;
    position: relative;
    z-index: 2;
    color: $charcoal;
  }
  .cloud-svg {
    position: absolute;
    top: 21vw;
    right: 9vw;
    z-index: 1;
    animation: floaty 9s ease-in-out infinite;
    fill: $pink-lavender;
  }
  .liliana-svg {
    position: absolute;
    top: 15vw;
    right: 25vw;
    z-index: -1;
  }
  .sub-heading {
    animation: fadeInUp 1s ease forwards;
    animation-delay: 2s;
  }
}

.custom-shape-divider-bottom-1708512159 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1708512159 svg {
  position: relative;
  display: block;
  width: calc(100% + 100px); /* Increase width */
  left: -50px; /* Adjust based on the extra width to center it */
  height: 133px;
}


@keyframes rotateRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation: fadeInDown 1s ease forwards;
}

.fadeInUp {
  animation: fadeInUp 1s ease forwards;
  animation-delay: 1s; // Delays this animation until the first one finishes
}

@keyframes floaty {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-48%, -52%) scale(1.05);
  }
}

@keyframes wave {
  0%, 100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -52%) rotate(-3deg);
  }
  50% {
    transform: translate(-48%, -50%) rotate(0deg);
  }
  75% {
    transform: translate(-50%, -48%) rotate(3deg);
  }
}