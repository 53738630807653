#about {
  background-color: $charcoal;
  color: $alabaster;
  align-items: center;
  justify-content: center;
  position: relative;

  .flower-svg {
    right: -81px;
    position: absolute;
    top: 200px;
    width: 183px;
    height: auto;
  }

  .circle-container {
    width: 200px; /* Adjust width and height according to your image */
    height: 200px;
    overflow: hidden; /* Ensure the image stays within the circle */
    border-radius: 50%; /* Make the container circular */

    img {
      width: 100%; /* Ensure the image fills the container */
      height: auto; /* Maintain aspect ratio */
      display: block; /* Remove default inline display */
    }
  }

  p {
    color: $alabaster;
    font-size: 16px;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-width: 900px;
    width: 100%;
  }

  .custom-shape-divider-bottom-1708657878 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-bottom-1708657878 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 112px;
    transform: rotateY(180deg);
  }

  .card3d {
    margin: 4px;
    transform: scale(1);
    perspective: 600px;
    position: relative;
    border-radius: 15px; /* Match this value to your image's border-radius. */
    overflow: hidden;
    box-shadow: 0 0 3px -1px transparent, 0 0 2px 1px transparent, 0 0 5px 0px transparent, 0px 10px 20px -5px black, 0 2px 15px -5px black, 0 0 20px 0px transparent;
    transform-style: preserve-3d;
    //transform-style: preserve-3d;
    //image-rendering: optimizeQuality;
    //  repeating-linear-gradient(
    //                  82deg,
    //                  rgb(218, 56, 50) calc(80px * 1),
    //                  rgb(219, 204, 86) calc(80px  * 2),
    //                  rgb(121, 199, 58) calc(80px  * 3),
    //                  rgb(58, 192, 183) calc(80px  * 4),
    //                  rgb(71, 98, 207) calc(80px  * 5),
    //                  rgb(170, 69, 209) calc(80px * 6),
    //                  rgb(218, 56, 50) calc(80px  * 10)
    //  ),
    //  radial-gradient(
    //                  farthest-corner circle at 50% 50%,
    //                  rgba(255, 255, 255, 0.6) 5%,
    //                  rgba(150, 150, 150, 0.3) 40%,
    //                  rgb(0, 0, 0) 100%
    //  );
    //
    //  background-blend-mode: color-dodge, color-burn, saturation, screen;
    //  background-position: center, center, center,
    //  calc(((50% - var(--posx)) * 2.5) + 50%)
    //  calc(((50% - var(--posy)) * 2.5) + 50%),
    //  center;
    //  background-size: cover, cover, cover, 600% 1200%, cover;
    //  filter: brightness(0.75) contrast(1.2) saturate(1.5);
    //  mix-blend-mode: color-dodge;
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  bottom: 0;
    //  clip-path: inset(10% 8.5% 52.5% 8.5%);
    //}
  }

  .card3d,
  .card3d img {
    transition: all 250ms ease-out;
  }

  .card3d img {
    height: auto;
    width: 300px;
    max-width: fit-content;
    transform-style: preserve-3d;
    image-rendering: optimizeQuality;
    position: relative;
  }

  //.card3d:hover {
  //  &:after {
  //    content: "";
  //    background-image: url(https://res.cloudinary.com/simey/image/upload/Dev/PokemonCards/galaxy.png);
  //    position: absolute;
  //    top: 0;
  //    bottom: 0;
  //    left: 0;
  //    right: 0;
  //    z-index: 9;
  //    mix-blend-mode: color-dodge;
  //  }
  //}

}

.card__glare {
  background-image: radial-gradient( farthest-corner circle at var(--pointer-x) var(--pointer-y), hsl(0,0%,80%), hsla(0, 0%, 74.9%, 0.25) 30%, hsl(0, 0%, 21.6%) 130% );
  filter: brightness(.9) contrast(2);
  opacity: calc( var(--pointer-from-center) * .9 );
  mix-blend-mode: hard-light;
  will-change: transform, opacity, background-image, background-size, background-position, background-blend-mode, filter;
}