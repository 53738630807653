form {
  max-width: 20vw;
  width: 100%;
  display: flex;
  flex-direction: column;

  #contactFormLabel {
    color: $pink-lavender;
    margin-bottom: 30px;
    font-size: 56px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  label {
    //font-weight: $bold;
    margin-bottom: 10px;
    font-size: 18px;
    color: $alabaster;
  }

  input,
  input[type='email'],
  textarea {
    border: 2px solid $alabaster;
    background-color: $alabaster;

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: $alabaster;
      --tw-ring-color: $mint;
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
      border-color: $mint;
    }
  }

  button {
    border-radius: 20px;
    padding: 10px;
    font-weight: $bold;
    border: 2px solid $alabaster;
    background-color: $charcoal;
    color: $alabaster;
    transition: .3s;
    margin-bottom: 80px;

    &:hover {
      box-shadow: 8px 8px $mint;
      transition: .3s;
      background-color: $charcoal;
      border-color: $charcoal;
      color: $alabaster;
    }
  }

  .field-error {
    font-weight: bold;
    color: $error;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .form-group-error input,
  .form-group-error input[type='email'],
  .form-group-error textarea {
      border: 2px solid $error;

      &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
        --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: $alabaster;
        --tw-ring-color: $error;
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
        border-color: $error;
      }
    }
}

.success-message {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  color: $alabaster;
  font-size: 20px;
}

.animate-in {
  opacity: 1;
  transform: scale(1);
}

.email-svg {
  fill: $pink-lavender;
  width: 12vw;
  offset-path: path("M180.3,318.3c0,0,19.8-73.6,84.7-73.2c89,0.4,120.5,149.7-29.5,149.7c-91,0-125.2-66.1-120-108.9c7.3-60,38.2-104,124.4-142.1C311.1,112.2,699.2,6.3,699.2,6.3");
}