.shape-fill-mint {
  fill: $mint;
}

.shape-fill-yellow {
  fill: $naples-yellow;
}

.shape-fill-pink {
  fill: $pink-lavender;
}

.shape-fill-black {
  fill: $charcoal;
}

.shape-fill-beige {
  fill: $alabaster;
}